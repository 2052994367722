<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link href="/" class="brand-logo">
        <ff-logo class="header-logo" />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Login" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-navbar-nav class="nav ml-auto btn-locale">
            <locale />
          </b-navbar-nav>
          <b-card-title class="mb-1 font-weight-bold" title-tag="h2">
            CRM - FUTURE FINANCIAL
          </b-card-title>
          <b-card-text class="mb-2">
            {{ $t('Modules.Auth.Label.Please login to your account') }}
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginForm" #default="{invalid}">
            <b-form class="auth-login-form mt-2" @submit.prevent="login">
              <!-- email -->
              <b-form-group :label="$t('Username')" label-for="login-username">
                <validation-provider
                  #default="{ errors }"
                  :name="$t('Username')"
                  vid="username"
                  rules="required"
                >
                  <b-form-input
                    id="login-username"
                    v-model="username"
                    :state="errors.length > 0 ? false : null"
                    name="login-username"
                    :placeholder="$t('Username')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">{{ $t("Password") }}</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('Password')"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      :placeholder="$t('Password')"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <div class="d-flex justify-content-between">
                  <b-link :to="{ name: 'auth-forgot-password' }">
                    <small>{{ $t('Forgot password') }}?</small>
                  </b-link>
                </div>
              </b-form-group>
              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid"
              >
                {{ $t("Login") }}
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import FfLogo from "@core/layouts/components/ff-logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  VBTooltip,
  BNavbarNav,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { $themeConfig } from "@themeConfig";
import AuthRepository from "@/modules/auth/repository/authRepository";
import { http } from "@/http";
import { getHomeRouteForLoggedInUser } from "@/auth/utils";
import Locale from "@core/layouts/components/app-navbar/components/Locale.vue";
import { mainDB } from "@/database/mainDB"

export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BNavbarNav,
    FfLogo,
    ValidationProvider,
    ValidationObserver,
    Locale,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      password: "",
      username: "",
      sideImg: require("@/assets/images/pages/login-v2.svg"),
      // validation rules
      required,
      email,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    login() {
      const vm = this;
      this.$refs.loginForm.validate().then((success) => {
        if (success) {
          vm.showLoading();
          AuthRepository.login({
            username: this.username,
            password: this.password,
          }).then((response) => {
            http.storeToken(response);
            vm.$ability.update(response.data.data.user.ability);

            var statesInsert = []
            var states = response.data.data.states
            states.forEach((item, index) => {
              statesInsert.push({
                id: item.id,
                stateName: item.stateName,
                stateCode: item.stateCode,
                stateLabel: item.stateCode + ' - ' + item.stateName,
              })
            })
            mainDB.states.bulkPut(statesInsert)

            var areaCodesInsert = []
            var areaCodes = response.data.data.areaCodes
            areaCodes.forEach((item, index) => {
              areaCodesInsert.push({
                stateId: item.stateId,
                stateLabel: item.stateLabel,
                areaCode: item.areaCode,
              })
            })
            mainDB.areaCodes.bulkPut(areaCodesInsert)

            var staffsInsert = []
            var staffs = response.data.data.staffs
            staffs.forEach((item, index) => {
              staffsInsert.push({
                id: item.id,
                shortName: item.shortName,
                fullName: item.fullName
              })
            })
            mainDB.staffs.bulkPut(staffsInsert)

            //Reset task session
            const taskSession = store.state.task
            taskSession.tabChoosed = 'cold_call'
            taskSession.indexChoosed = null,
            taskSession.isShowDetails = false,
            taskSession.isCalling = false,
            taskSession.taskDetailsData = {},
            taskSession.callResult = {
              id: null,
              createdAt: null,
              status: null,
              note: null
            }
            store.dispatch("task/update", taskSession);

            this.$router
              .replace(getHomeRouteForLoggedInUser(response.data.data.user.role))
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title:
                      vm.$i18n.t("Wellcome") +
                      " " +
                      response.data.data.user.fullName,
                    icon: "CoffeeIcon",
                    variant: "success",
                  },
                });
              });
          });
        }
      });
    },
  },
  setup() {
    // App Name
    const { appName } = $themeConfig.app;
    const user = store.state.user
    user.fullName = ""
    user.username = ""
    user.role = ""
    user.ability = null
    store.dispatch('user/update', user)

    return {
      appName,
    };
  },
  watch: {
    $currentLocale(locale) {
      localize(locale);
      this.$refs.loginForm.reset();
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
